<template>
  <v-container grid-list-xs>

    <v-snackbar v-model="snackErreur" color="red">
      {{snackMessage}}
      <v-btn text color="primary" @click.native="snackErreur = false">Close</v-btn>
    </v-snackbar>

    <h1>Historique des mouvements {{((container != undefined) && (container != "")) ? ' du container ' + container : ''}}</h1>

    <!-- Boutons de commandes générales -->
    <h-group class="my-2">

      <v-text-field v-model="search" append-icon="mdi-magnify" label="Recherche" class="mx-4" style="max-width: 250px" single-line hide-details></v-text-field>

      <v-spacer></v-spacer> 

      <v-text-field name="dateLimite" class="mr-4" style="max-width: 200px" type="date" label="Date limite" v-model="dateLimite"></v-text-field>  

      <v-btn color="lightgrey" class="mr-4" @click="rafraichir()">
        <v-icon left>mdi-refresh</v-icon>
        Rafraîchir</v-btn>

      <v-btn color="lightgrey" class="mr-4" @click="exporter()">
        <v-icon left>mdi-export</v-icon>
        Exporter</v-btn>

      <v-btn color="success" class="mr-4" @click="retour()">
        <v-icon left>mdi-arrow-left</v-icon>
        Retour</v-btn>
    </h-group>

    <v-data-table dense :loading="loading" :headers="headers" :items="historique" item-key="ctCtId" class="elevation-1" :search="search" :items-per-page="1000">
      <template v-slot:item.ctdaDate="{ item }">
        {{ computeDate(item.ctdaDate) }}
      </template>
    </v-data-table>
    
  </v-container>
</template>

<script>
import axios from 'axios';

export default {

  data() {
    return {

      historique: [],
      loading: false,
      snackErreur: false,
      snackMessage: "",
      container: "",

      headers: [
         { text: 'Container', value: 'ctdaContainer' },
         { text: 'Date', value: 'ctdaDate' },
         { text: 'Opération', value: 'ctdaOperation' },
         { text: 'Durée présence', value: 'duree' },
         { text: 'Train', value: 'ctdaTrain' },
         { text: 'Wagon', value: 'ctdaWagon' },
         { text: 'Scac', value: 'ctdaScac' },
         { text: 'Statut', value: 'ctdaStatut' },
         { text: 'Expedition', value: 'ctdaExpedition' },
         { text: 'Seal', value: 'ctdaSeal' },
         { text: 'NW booking number', value: 'ctdaNestleBookingNumber' },
         { text: 'Terminal', value: 'ctdaTerminal' },
      ],

      search: "",

      voirUniquementContainersPresents: true,

      dateLimite: undefined,

    }
  },

  methods: {
    rafraichir() {
      this.loading = true;
      var filter;
      var dateFilter = {"ctdaDate": {"gt": new Date(this.dateLimite)}};

      if ((this.container != undefined) && (this.container != "")) {
        // Filtre sur la date et le numéro de container
        filter =  {
          "where": {"and": [
            dateFilter,
            {"ctdaContainer": this.container}
          ]},
          "order": "ctdaDate DESC"
        }
      }
      else {
        // Filtre sur la date uniquement
        filter =  {"where": dateFilter, "order": "ctdaDate DESC"}
      }
      
      var url = "/api/ctdaContainerDepartArrivees?filter=" + JSON.stringify(filter) + "&access_token=" + this.$user.id;
      axios.get(url)
        .then(function (response) {
          const historique = response.data;
          for (var idx = 0; idx < historique.length; idx++) {
            const histo = historique[idx];
            if (histo.ctdaOperation == "départ") {
              if (idx < historique.length - 1) {
                if (historique[idx + 1].ctdaOperation == "arrivée") {
                  // Calcul de la durée
                  const d1 = new Date(histo.ctdaDate);
                  const d0 = new Date(historique[idx + 1].ctdaDate);
                  const duree = (d1.getTime() - d0.getTime()) / 1000 / 3600 / 24;
                  histo.duree = duree >= 0 ? Math.round(duree).toFixed(0) + " jour" + (Math.round(duree) >=2 ? "s" : "") : "-";
                }
              }
            }

          }
          this.historique = response.data;
          this.loading = false;
        }.bind(this))
        .catch(error => {
          if (error.response.status == 401) {
            // Erreur "accès refusé", redirection vers la page de connexion
            this.$router.push('/login?to=/containers')
          }
          else {
            this.snackMessage = error.message + " : " + error.response.statusText;
            this.snackErreur = true
            this.loading = false;
            this.containersFiltres = [];
            this.containers   = [];
          }
        });
    },

    exporter() {
      this.$exporterCsv(this.historique, "export-historique-containers.csv")
    },

    computeDate(sdate) {
      return sdate != null ? sdate.substr(0,10) : "";
    },

    retour() {
      this.$router.go(-1);
    }
  },

  mounted() {
    this.dateLimite = new Date(Date.now() - 86400 * 1000 * 366).toISOString().substr(0,10)
    this.container = this.$route.params.container;
    this.rafraichir();
  },

  watch: {
    $route(to) {
      if (to.url.startsWith('/histocontainer/')) {
        this.container = this.$route.params.container;
        this.rafraichir
      }
    }
  },

}
</script>

<style>

</style>